<template>
  <fragment>
    <flyout name="partner-flyout" :title="$props.data.name">
      <template v-if="$props.displayFullProfileButton" #action>
        <a class="button secondary" @click="showFullProfile">Full Profile</a>
      </template>
      <template #contactaction>
        <div v-if="getPermission('sendComms')" class="contact-block flyout-header">
          <div class="communication-tag"><i class="icon med-size icon-mail"></i><span @click="communication">Email</span></div>
          <div class="communication-tag"><i class="icon med-size icon-message-square"></i><span @click="communication">Text</span></div>
          <div class="communication-tag"><i class="icon med-size icon-phone"></i><a :href="`tel:${$props.data.phone}`">Phone</a></div>
        </div>
      </template>
      <div class="partner-info-heading">
        <div class="partner-heading">
          <h3 class="flyout-heading-h3">Partner Info</h3>
        </div>
        <!-- <div class="partner-status">{{ $props.data.partnerStatus }}</div> -->
        <div v-if="partnerStatusCurrent=='Driver deactivated'">
          <p> <b>{{partnerStatusCurrent}}</b></p>

        </div>
        <div v-else> <meatball id="partner-status-meatball" style="font-weight: bold" left large :title-color="statusColor()" :title="partnerStatusCurrent">
          <menu-item v-if="showMeatBallMenu" style="font-weight: normal" label="Inactive" :on-click="() => deactivatePartners('INACTIVE')" />
          <menu-item v-if="!showMeatBallMenu" style="font-weight: normal" label="Active" :on-click="() => deactivatePartners('ACTIVE')" />
        </meatball></div>
       
      </div>
      <p>{{ $props.data.name }}</p>
      <p>{{ $props.data.username }}</p>
      <p>
        <a :href="`mailto:${$props.data.email}`">{{ $props.data.email }}</a>
      </p>
      <p>{{ $props.data.phone | formatPhone }}</p>
      <hr />
      <h3 class="flyout-heading-h3">Location</h3>
      <p>{{ $props.data.locationName }}</p>
      <!-- <p>{{ getStatusDate }}</p> -->
      <div v-if="$props.data.approvalDate || $props.data.lastPay">
        <p v-if="$props.data.approvalDate">
          <span>Approved</span>
          <span style="color: #747474"> {{ $props.data.approvalDate | formatDateDDMMMYYYY }}</span>
        </p>
        <p v-if="$props.data.lastPay != ''">
          <span>Lastpay</span>
          <span style="color: #747474"> {{ $props.data.lastPay | formatDateTimePacific }}</span>
        </p>
      </div>
      <loader v-if="$apollo.loading" />
      <fragment v-else>
        <hr />
        <h3 class="flyout-heading-h3">Maintenance Alerts</h3>
        <div>
          <!-- <h6 class="label">View candidate screening reports <a :href="reportLinks" target="_blank">here</a></h6> -->
        </div>
        <maintenance-alerts v-if="$props.data" v-model="screeningVerified" :data="$props.data" :checkr-data="reportLinks" :cbc-date="cbcDate" @cbcverified="refetchPartnerDetails()"
        @inactivepartner="deactivatePartners(`INACTIVE`)"
        @dlChange="updatedDL"
        @refresh="refresh"
        />
      </fragment>
      <hr />

            <div v-if="getPermission('rwNotes')">
        <div class="view-notes-btn">
          <a class="button ghost" @click="notesToggel"> View partner notes<i class="right-icon" :class="{ 'icon-chevron-up': toggleNotes, 'icon-chevron-down': !toggleNotes }"> </i> </a>
        </div>
          <div v-if="toggleNotes">
          <div v-if="$props.data.notes">
            <div class="notes-user-main">
            <div class="notes-user-card">
                <div class="notes-user-message"> <p>{{$props.data.notes}}</p></div>
              </div>
               </div>
        </div>

        
            <div v-if="notesData" class="notes-user-main">
            <div v-for="item in notesData" :key="item.date " class="notes-user-card">
                <div class="user-heading"> <p class="user-p">{{item.user}}</p> <p class="notes-date">{{item.date | formatDatePacific}}</p></div>
                <div class="notes-user-message"> <p>{{item.message}}</p></div>
              </div>
               </div>
            <div v-if="!$props.data.notes && !notesData"> 
              No Notes
            </div>
        </div>
      </div>

      <div v-if="getPermission('rwNotes')" class="field">
        <label>Partner Notes</label>
        <div class="has-charLimit">
          <textarea v-model="notesText" placeholder="" @blur="saveNotes($event.target.value)"></textarea>
          <p class="charlimit">500</p>
        </div>
      </div>
    </flyout>
    <single-action :single-obj="singlePartnerObj" :on-close="closeComms" />
  </fragment>
</template>
<script>
import { Flyout, Loader, MenuItem, Meatball } from "@/components";
import { mapActions, mapGetters } from "vuex";
import { Fragment } from "vue-fragment";
import micromodal from "micromodal";
import MaintenanceAlerts from "@/modules/admin/onboarding/components/MaintenanceAlerts";
// import { GET_CBC_DATE } from "@/modules/admin/onboarding/graph/subscriptions";
import { DateTime } from "luxon";
// import { SET_CANDIDATE_NOTES_NEW  } from "@/modules/admin/onboarding/graph/mutations";
import { SingleAction } from "@/modules/shared/communications";
import { restApi } from "@/http/http.config";
import { encodeWithParam } from "@/filters";
import partnerMethods from "../../partners/profile/Helper/Index";

export default {
  components: {
    Flyout,
    Loader,
    Fragment,
    MaintenanceAlerts,
    MenuItem,
    Meatball,
    SingleAction,
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: () => {},
    },
    displayFullProfileButton: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data: () => ({
    funnelSteps: [],
    partnerSteps: [],
    eligibilityQuestions: [],
    singlePartnerObj: [],
    partnerApplication: {},
    insuranceImageURL: "",
    notesText:"",
    screeningVerified: true,
    toggleNotes:false,
    loadComponent: false,
    reportsConfig: {},
    meatBallMenu: ["Active", "Newly Approved", "Idle"],
    dynamicMeatBallTitle: "",
    cbcDate:""
  }),
  computed: {
    ...mapGetters(["getUsername", "getPermission"]),
    mailLink() {
      return `mailto:${this.$props.data.email}`;
    },
    notesData(){
      return this.$props.data.notesList ?.notes ? this.$props.data.notesList.notes : null;
    },
    reportLinks() {
      if(this.$props.data.reportConfig){
        return `${this.$props.data.reportConfig.rootCheckrLink}/candidates/${this.$props.data.checkrId}${this.$props.data.reportConfig.checkrTestQuery}`;
      }
      else{
        return ''
      }
     
    },
    showMeatBallMenu() {
      return this.meatBallMenu.includes(this.sentenceCase());
    },
    showStatusTile() {
      return this.$props.data.partnerStatus;
    },
    partnerStatusCurrent() {
      // return this.dynamicMeatBallTitle != "" ? this.dynamicMeatBallTitle : this.sentenceCase();
      return this.sentenceCase();
    },
  },
  methods: {
    ...mapActions(["showFlyout"]),
    statusColor() {
        let status = this.dynamicMeatBallTitle != "" ? this.dynamicMeatBallTitle : this.$props.data.partnerStatus || this.$props.data.status;
      if (status == "NEWLY_APPROVED") {
        status = "Newly Approved";
      }
      switch (status?.toLowerCase()) {
        case "active":
          return "#1B803B";
        case "idle":
          return "#A85D07";
        case "inactive":
          return "#C2272C";
        case "newly approved":
          return "#1B803B";
      }
    },
    showFullProfile() {
      this.showFlyout("");
      this.$router.push({ name: "partner", params: { id: this.$props.data.partnerID } });
    },
    openComms() {
      document.body.classList.add("no-scroll");
      this.showFlyout("comms-flyout");
    },
    closeComms() {
      this.singlePartnerObj = [];
      document.body.classList.remove("no-scroll");
      this.showFlyout("");
      this.loadComponent = false;
    },
    openModal(id) {
      return () => micromodal.show(id, {});
    },
    communication() {
      this.singlePartnerObj = [];
      this.loadComponent = true;
      this.singlePartnerObj.push(this.$props.data);
      document.body.classList.add("no-scroll");
      this.showFlyout("single-comms-flyout");
    },
    format(date) {
      let dateToFormat = date;
      if (typeof date === "string") {
        dateToFormat = DateTime.fromISO(date);
      }
      return dateToFormat.toFormat("DD");
    },

    async saveNotes(message) {
      if(message.length > 0){
        let localObj={"user":localStorage.getItem("role"),"date":new Date().toISOString(),"message":message}
      if(!this.$props.data.notesList){
        this.$props.data.notesList={"notes":[]}
      }
      this.$props.data.notesList.notes.push(localObj)
      await restApi.put(`/partner/saveNotes/${this.$props.data.partnerID}`,
      {"param": window.btoa(JSON.stringify({ notes: this.$props.data.notesList })) 
      })
      .then(() => {
          this.notesText="";
        });
      }
    },
      notesToggel(){
      this.toggleNotes= !this.toggleNotes;
    },

    async deactivatePartners(status) {
        await restApi.post(`/partner/changeCandidateStatus`,encodeWithParam({id: this.$props.data.candidateID,status: status,updateDate: DateTime.utc().toISO()})).then(() => {
          this.$props.data.status = status;
          this.$emit("statusupdated");
        });
    },
    sentenceCase() {
      const sentence = this.dynamicMeatBallTitle != "" ? this.dynamicMeatBallTitle : this.$props.data.partnerStatus || this.$props.data.status;
      if (sentence) {
        if (sentence === "Newly Approved" || sentence === "NEWLY_APPROVED") {
          return "Newly Approved";
        }
        let removeCharacters = sentence?.replaceAll("_", " ");
        return removeCharacters?.charAt(0) + removeCharacters?.slice(1).toLowerCase();
      } else {
        return "";
      }
    },
    refetchPartnerDetails() {
       this.$emit("cbcverified");
    },
    updatedDL(data){
      this.$props.data.decoded.dexpiration = data.licenseExpiry;
      this.$props.data.decoded.dnumber = data.licenseNumber;
      this.$props.data.decoded.dstate = data.licenseState;
      this.$props.data.dlNumber = partnerMethods.maskField(data.licenseNumber);
      this.$props.data.dlExpiration = partnerMethods.maskDate(data.licenseExpiry);
      this.$props.data.dlState = partnerMethods.maskField(data.licenseState);
      this.$emit("statusupdated");
    },
    refresh() {
      this.$emit("refresh", {});
    },
  },
  mounted() { 
    micromodal.init({
      onShow: function () {
        document.body.classList.add("no-scroll");
      },
      onClose: function () {
        document.body.classList.remove("no-scroll");
      },
    });
  },
};
</script>
